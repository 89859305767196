
import { defineComponent, ref } from 'vue'
import Footer from './components/Footer/Footer.vue'
import router from './router/index'

export default defineComponent({
  components: {
    Footer,
  },
  setup() {
    return {}
  },
})
